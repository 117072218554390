const defaultIconSize = 28;
const defaultIconColor = "rgb(34, 34, 34)";

export const Phone = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M480.3 320.3l-98.2-42.1c-21.41-9.281-46.64-3.109-61.2 14.95l-27.44 33.5c-44.78-25.75-82.29-63.25-108-107.1l33.55-27.48c17.91-14.62 24.09-39.7 15.02-61.05L191.7 31.53c-10.16-23.2-35.34-35.86-59.87-30.19L40.58 22.4C16.7 27.86 0 48.83 0 73.39c0 241.9 196.7 438.6 438.6 438.6 24.56 0 45.53-16.69 50.1-40.53l21.06-91.34c6.64-24.62-6.16-49.82-29.46-59.82zm-16.4 49l-21.09 91.41c-.469 1.1-2.109 3.281-4.219 3.281-215.4 0-390.6-175.2-390.6-390.6 0-2.094 1.297-3.734 3.344-4.203l91.34-21.08c.312-.078.64-.11.953-.11 1.734 0 3.36 1.048 4.047 2.61l42.14 98.33a4.353 4.353 0 01-1.25 5.062L139.8 193.1c-8.625 7.062-11.25 19.14-6.344 29.14 33.01 67.23 88.26 122.5 155.5 155.5 9.1 4.906 22.09 2.281 29.16-6.344l40.01-48.87c1.109-1.406 3.187-1.938 4.922-1.125l98.26 42.09c1.892 1.709 2.992 3.809 2.592 5.809z"
      ></path>
    </svg>
  );
};

export const Heart = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M462.1 62.86C438.8 41.92 408.9 31.1 378.7 32c-37.49 0-75.33 15.4-103 43.98l-19.7 20.27l-19.7-20.27C208.6 47.4 170.8 32 133.3 32C103.1 32 73.23 41.93 49.04 62.86c-62.14 53.79-65.25 149.7-9.23 207.6l193.2 199.7C239.4 476.7 247.6 480 255.9 480c8.332 0 16.69-3.267 23.01-9.804l193.1-199.7C528.2 212.5 525.1 116.6 462.1 62.86zM437.6 237.1l-181.6 187.8L74.34 237.1C42.1 203.8 34.46 138.1 80.46 99.15c39.9-34.54 94.59-17.5 121.4 10.17l54.17 55.92l54.16-55.92c26.42-27.27 81.26-44.89 121.4-10.17C477.1 138.6 470.5 203.1 437.6 237.1z"
      />
    </svg>
  );
};

export const HeartThin = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M462.1 62.86C438.8 41.92 408.9 32 378.7 32c-37.49 0-75.33 15.4-103 43.98L256 96.25l-19.7-20.27C208.6 47.4 170.8 32 133.3 32c-30.2 0-60.07 9.92-84.26 30.86C-13.1 116.7-16.21 212.5 39.81 270.5l193.2 199.7c6.39 6.5 14.59 9.8 22.89 9.8 8.336 0 16.69-3.269 23.01-9.804l193.1-199.7C528.2 212.5 525.1 116.6 462.1 62.86zm-1.5 196.54L267.5 459.1c-3.1 3.2-7.2 4.9-12.4 4.9-4.336 0-8.414-1.75-11.49-4.93l-193.1-199.7c-23.93-24.77-36.76-59.07-35.2-94.13 1.578-35.36 16.98-67.43 43.39-90.28C79.89 57.32 105.4 48 133.3 48c34.13 0 67.49 14.23 91.54 39.05l31.18 32.19 31.18-32.19C311.2 62.23 344.6 48 378.7 48c27.89 0 53.4 9.32 73.77 26.96 26.41 22.86 41.82 54.93 43.37 90.29 1.56 35.05-11.24 69.35-35.24 94.15z"
      ></path>
    </svg>
  );
};

export const HeartFilled = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M472.1 270.5L279 470.2c-12.64 13.07-33.27 13.08-45.91.01l-193.2-199.7C-16.21 212.5-13.1 116.7 49.04 62.86 103.3 15.88 186.4 24.42 236.3 75.98L256 96.25l19.7-20.27c49.95-51.56 132.1-60.1 187.3-13.12 62.1 53.74 65.2 149.64 9.1 207.64z"
      ></path>
    </svg>
  );
};

export const User = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M272 304h-96C78.8 304 0 382.8 0 480c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32C448 382.8 369.2 304 272 304zM48.99 464C56.89 400.9 110.8 352 176 352h96c65.16 0 119.1 48.95 127 112H48.99zM224 256c70.69 0 128-57.31 128-128c0-70.69-57.31-128-128-128S96 57.31 96 128C96 198.7 153.3 256 224 256zM224 48c44.11 0 80 35.89 80 80c0 44.11-35.89 80-80 80S144 172.1 144 128C144 83.89 179.9 48 224 48z"
      />
    </svg>
  );
};

export const BigDot = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50%" cy="50%" r="20" fill={color} />
    </svg>
  );
};

export const Hamburger = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M424 392H23.1C10.8 392 0 402.8 0 415.1C0 429.2 10.8 440 23.1 440H424c13.2 0 24-10.8 24-23.1C448 402.8 437.2 392 424 392zM424 72H23.1C10.8 72 0 82.8 0 95.1S10.8 120 23.1 120H424c13.2 0 24-10.8 24-23.1S437.2 72 424 72zM424 232H23.1C10.8 232 0 242.8 0 256c0 13.2 10.8 24 23.1 24H424C437.2 280 448 269.2 448 256S437.2 232 424 232z"
      />
    </svg>
  );
};

export const Bag = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M416 160h-72V120C344 53.83 290.2 0 224 0S104 53.83 104 120V160H32C14.33 160 0 174.3 0 192v240C0 476.2 35.82 512 80 512h288c44.18 0 80-35.82 80-80V192C448 174.3 433.7 160 416 160zM152 120C152 80.3 184.3 48 224 48s72 32.3 72 72V160h-144V120zM400 432c0 17.64-14.36 32-32 32h-288c-17.64 0-32-14.36-32-32v-224h56v56C104 277.3 114.8 288 128 288s24-10.75 24-24V208h144v56C296 277.3 306.8 288 320 288s24-10.75 24-24V208h56V432z"
      />
    </svg>
  );
};
export const Search = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Interface / Search_Magnifying_Glass">
        <path
          id="Vector"
          d="M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const Shipping = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
    >
      <path
        fill={color}
        d="M616 368h-8V251.9c0-19-7.75-37.5-21.12-50.88l-83.96-83.96C489.4 103.6 471.2 96 452.1 96H416V56c0-30.93-25.1-56-56-56H120C89.07 0 64 25.07 64 56v40H15.1C7.2 96 0 103.2 0 111.1S7.2 128 15.1 128h224c9.7 0 16.9-7.2 16.9-16s-7.2-16-16-16H112V56c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v312H242.8c-16.7-28.6-47.3-48-82.8-48-17.62 0-33.75 5.125-48 13.25V288H64v124.8c0 52.84 41.32 98.19 94.16 99.18C212 512.1 256 469.6 256 416h128c0 53 43 96 96 96s96-43 96-96h40c13.2 0 24-10.8 24-23.1S629.2 368 616 368zm-456 96c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm256-320h36.13c6.25 0 12.5 2.625 17 7l72.97 73H416v-80zm64 320c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-100.9c-17.2-25.9-46.6-43.1-80-43.1-24.75 0-47 9.625-64 24.88V272h144v91.1zM256 240c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16s7.2 16 15.1 16h224c9.7 0 16.9-7.2 16.9-16zm16-48c8.8 0 16-7.2 16-16s-7.2-16-16-16H48c-8.8 0-16 7.2-16 16s7.2 16 15.1 16H272z"
      ></path>
    </svg>
  );
};

export const Customer = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        fill={color}
        d="M256 1.349c-141.397.01-256 114.603-256 256-.038 62.382 22.775 118.201 60 161.636 37.206 43.474 88.708 74.804 146.536 89.388l.01.01c6.182 1.55 12.593 2.268 19.082 2.268 17.301-.057 35.215-5.014 49.962-15.482 7.34-5.254 13.857-12.001 18.554-20.307 4.708-8.287 7.445-18.124 7.427-28.68 0-10.43-2.584-21.454-7.809-32.803-1.78-3.867-3.982-7.809-6.374-11.771 5.531-4.259 8.996-8.096 9.934-9.034 2.946-2.966 10.344-17.378 1.837-26.765-8.546-9.378-36.651-29.282-43.886-32.23-7.234-2.938-17.492 2.881-21.435 11.503-3.961 8.612-8.526 7.014-8.526 7.014s-17.034-9.56-44.851-47.808c-27.828-38.288-31.627-57.417-31.627-57.417s-.124-4.833 9.292-5.952c9.416-1.092 18.105-9.044 17.54-16.843-.555-7.789-10.832-40.679-17.119-51.684-6.297-11.004-22.278-8.402-26.01-6.508-3.752 1.895-42.623 18.67-38.613 61.675 4.02 43.005 21.522 79.694 42.575 108.669 21.071 28.977 50.574 56.948 90.238 74.029 12.632 5.435 24.067 5.895 33.895 3.933 2.602 4.048 4.785 7.895 6.421 11.454 3.761 8.201 5.12 14.986 5.12 20.536-.02 5.636-1.34 10.124-3.618 14.21-3.416 6.076-9.464 11.359-17.283 15.11-7.751 3.742-17.081 5.77-25.645 5.751-4.278 0-8.345-.488-11.923-1.388h.018c-52.172-13.138-98.392-41.407-131.387-79.99-33.014-38.631-52.919-87.339-52.938-142.526 0-62.622 25.339-119.187 66.363-160.239C136.813 56.087 193.377 30.756 256 30.747c62.622.01 119.177 25.34 160.229 66.364 41.034 41.052 66.364 97.617 66.374 160.239 0 38.67-9.666 74.966-26.698 106.785a226.875 226.875 0 01-35.168 48.813c-5.579 5.895-5.321 15.206.584 20.776 5.894 5.578 15.195 5.32 20.775-.584a255.723 255.723 0 0039.732-55.139C501.071 342.067 512 300.938 512 257.349c-.01-141.397-114.612-255.99-256-256z"
      ></path>
      <path
        fill={color}
        d="M253.196 251.522c5.943-6.775 9.532-13.819 9.532-22.794 0-15.196-10.919-26.804-28.871-26.804-18.106 0-28.603 11.321-29.57 24.316-.143.823.277 1.512 1.1 1.656l15.206 2.488c.966.133 1.512-.278 1.512-1.11.832-7.186 4.699-11.052 11.052-11.052 6.498 0 10.23 4.564 10.23 10.775 0 4.842-1.933 8.842-5.532 12.994l-33.014 39.236c-.556.689-.699 1.1-.699 1.932v12.843c0 .832.554 1.388 1.388 1.388h55.818c.822 0 1.378-.556 1.378-1.388v-13.674c0-.823-.556-1.379-1.378-1.379h-32.89v-.277l24.738-29.15zM342.325 266.995h-6.22c-.556 0-.833-.277-.833-.833v-20.306c0-.833-.546-1.378-1.378-1.378h-16.03c-.822 0-1.378.545-1.378 1.378v20.306c0 .556-.277.833-.832.833H298.39v-.412l28.182-61.76c.278-.833 0-1.378-.966-1.378h-17.55c-.967 0-1.522.268-1.934 1.1l-28.182 62.038c-.277.546-.277 1.1-.277 1.656v14.086c0 .832.555 1.387 1.378 1.387h36.613c.555 0 .832.268.832.822v11.465c0 .832.556 1.388 1.378 1.388h16.03c.832 0 1.378-.556 1.378-1.388v-11.465c0-.555.277-.822.833-.822h6.22c.823 0 1.379-.556 1.379-1.387v-13.953c0-.831-.556-1.377-1.379-1.377zM417.081 253.867c0-14.642-8.009-24.871-22.794-24.871-8.566 0-14.23 3.464-17.273 7.598h-.134v-31.77c0-.833-.554-1.378-1.388-1.378h-16.574c-.833 0-1.378.545-1.378 1.378v91.177c0 .832.545 1.388 1.378 1.388h16.574c.833 0 1.388-.556 1.388-1.388v-38.402c0-7.321 3.866-12.163 10.498-12.163 6.909 0 10.363 4.842 10.363 12.163v38.402c0 .832.556 1.388 1.378 1.388h16.584c.823 0 1.378-.556 1.378-1.388v-42.134z"
      ></path>
    </svg>
  );
};

export const Payment = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 10.5V8a2 2 0 00-2-2H5a2 2 0 00-2 2v9a2 2 0 002 2h7"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 16.429c0-.474.384-.858.857-.858h4.286c.473 0 .857.384.857.858v2.714a.857.857 0 01-.857.857h-4.286a.857.857 0 01-.857-.857v-2.714z"
      ></path>
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16.714 14.286a1.286 1.286 0 112.572 0v1.285h-2.572v-1.285zM3 10h17.5M7 15h2"
      ></path>
    </svg>
  );
};

export const BackArrow = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        d="M15 19.5L7.5 12L15 4.5"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PendingLock = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill={color}
    >
      <path
        d="M9 4H10C10.1326 4 10.2598 4.05268 10.3536 4.14645C10.4473 4.24021 10.5 4.36739 10.5 4.5V10.5C10.5 10.6326 10.4473 10.7598 10.3536 10.8536C10.2598 10.9473 10.1326 11 10 11H2C1.86739 11 1.74021 10.9473 1.64645 10.8536C1.55268 10.7598 1.5 10.6326 1.5 10.5V4.5C1.5 4.36739 1.55268 4.24021 1.64645 4.14645C1.74021 4.05268 1.86739 4 2 4H3V3.5C3 2.70435 3.31607 1.94129 3.87868 1.37868C4.44129 0.81607 5.20435 0.5 6 0.5C6.79565 0.5 7.55871 0.81607 8.12132 1.37868C8.68393 1.94129 9 2.70435 9 3.5V4ZM5.5 7.866V9H6.5V7.866C6.69064 7.75593 6.83964 7.58604 6.92388 7.38266C7.00812 7.17928 7.0229 6.95379 6.96593 6.74116C6.90895 6.52852 6.78341 6.34063 6.60876 6.20662C6.43412 6.07261 6.22013 5.99997 6 5.99997C5.77987 5.99997 5.56588 6.07261 5.39124 6.20662C5.21659 6.34063 5.09105 6.52852 5.03407 6.74116C4.9771 6.95379 4.99188 7.17928 5.07612 7.38266C5.16036 7.58604 5.30936 7.75593 5.5 7.866ZM8 4V3.5C8 2.96957 7.78929 2.46086 7.41421 2.08579C7.03914 1.71071 6.53043 1.5 6 1.5C5.46957 1.5 4.96086 1.71071 4.58579 2.08579C4.21071 2.46086 4 2.96957 4 3.5V4H8Z"
        fill="#282828"
      />
    </svg>
  );
};

export const Ellipse = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={color}
    >
      <circle cx="10" cy="10" r="10" fill="#282828" />
      <text
        x="10"
        y="10"
        textAnchor="middle"
        alignmentBaseline="middle"
        fill="white"
        fontSize="10"
      >
        i
      </text>
    </svg>
  );
};

export const WalletLogo = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width="130"
      height="178"
      viewBox="0 0 130 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.4449 136.164L108.333 82.3308L78.7466 69.6666L48.8582 123.501L78.4449 136.164Z"
        fill="#32B566"
      />
      <path
        d="M78.4449 136.164L108.333 82.3308L78.7466 69.6666L48.8582 123.501L78.4449 136.164Z"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.6247 80.9899L87.1559 76.9323C85.3663 80.1744 80.9264 81.507 77.234 79.9357L53.9017 121.943L76.713 131.709L100.045 89.7017C96.3529 88.1304 94.8125 84.232 96.6247 80.9899Z"
        fill="#F6F9EA"
      />
      <path
        d="M96.6247 80.9899L87.1559 76.9323C85.3663 80.1744 80.9264 81.507 77.234 79.9357L53.9017 121.943L76.713 131.709L100.045 89.7017C96.3529 88.1304 94.8125 84.232 96.6247 80.9899Z"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.294 83.8465L80.4877 74.0849L53.9047 121.965L76.7108 131.727L103.294 83.8465Z"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.8584 105.534C85.1496 105.534 88.6283 102.48 88.6283 98.712C88.6283 94.9442 85.1496 91.8897 80.8584 91.8897C76.5673 91.8897 73.0886 94.9442 73.0886 98.712C73.0886 102.48 76.5673 105.534 80.8584 105.534Z"
        fill="#32B566"
      />
      <path
        d="M80.8584 105.534C85.1496 105.534 88.6283 102.48 88.6283 98.712C88.6283 94.9442 85.1496 91.8897 80.8584 91.8897C76.5673 91.8897 73.0886 94.9442 73.0886 98.712C73.0886 102.48 76.5673 105.534 80.8584 105.534Z"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.8292 156.333H35.2584C27.7603 156.333 21.6667 150.983 21.6667 144.399V97.4587C21.6667 90.8752 27.7603 85.5248 35.2584 85.5248H82.8292C90.3272 85.5248 96.4208 90.8752 96.4208 97.4587V144.399C96.4208 151.002 90.3499 156.333 82.8292 156.333Z"
        fill="#E2B641"
      />
      <path
        d="M82.8292 156.333H35.2584C27.7603 156.333 21.6667 150.983 21.6667 144.399V97.4587C21.6667 90.8752 27.7603 85.5248 35.2584 85.5248H82.8292C90.3272 85.5248 96.4208 90.8752 96.4208 97.4587V144.399C96.4208 151.002 90.3499 156.333 82.8292 156.333Z"
        fill="#2C2B45"
      />
      <path
        d="M83.1624 156.333H35.5916C28.0936 156.333 22 150.983 22 144.399V97.4587C22 90.8752 28.0936 85.5248 35.5916 85.5248H83.1624C90.6605 85.5248 96.7541 90.8752 96.7541 97.4587V144.399C96.7541 151.002 90.6831 156.333 83.1624 156.333Z"
        fill="#EEC14A"
      />
      <path
        d="M82.8292 153.927H35.2584C27.7603 153.927 21.6667 148.576 21.6667 141.993V144.399C21.6667 150.983 27.7603 156.333 35.2584 156.333H82.8292C90.3272 156.333 96.4208 150.983 96.4208 144.399V141.993C96.4208 148.576 90.3499 153.927 82.8292 153.927Z"
        fill="#D89979"
      />
      <path
        d="M82.8292 85.5248H35.2584C27.7603 85.5248 21.6667 90.4376 21.6667 96.4841V99.0499C21.6667 93.0034 27.7603 88.0906 35.2584 88.0906H82.8292C90.3272 88.0906 96.4208 93.0034 96.4208 99.0499V96.4841C96.4208 90.4376 90.3499 85.5248 82.8292 85.5248Z"
        fill="#F3B598"
      />
      <path
        opacity="0.3"
        d="M66.9721 114.664H54.7397C52.497 114.664 50.6622 113.053 50.6622 111.083V89.105C50.6622 87.1359 52.497 85.5248 54.7397 85.5248H71.0496V111.083C71.0496 113.053 69.2374 114.664 66.9721 114.664Z"
        fill="#474747"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.8292 156.333H35.2584C27.7603 156.333 21.6667 150.983 21.6667 144.399V97.4587C21.6667 90.8752 27.7603 85.5248 35.2584 85.5248H82.8292C90.3272 85.5248 96.4208 90.8752 96.4208 97.4587V144.399C96.4208 151.002 90.3499 156.333 82.8292 156.333Z"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.46">
        <path
          opacity="0.46"
          d="M26.6504 144.101V97.7571C26.6504 93.5802 30.5014 90.1989 35.2584 90.1989H82.8292C87.5863 90.1989 91.4372 93.5802 91.4372 97.7571V144.081C91.4372 148.258 87.5863 151.639 82.8292 151.639H35.2584C30.5014 151.659 26.6504 148.278 26.6504 144.101Z"
          stroke="#474747"
          strokeWidth="0.273765"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-dasharray="0.96 0.96"
        />
      </g>
      <path
        d="M65.16 112.476H52.9275C50.6849 112.476 48.85 110.865 48.85 108.896V84.6299C48.85 82.6608 50.6849 81.0497 52.9275 81.0497H65.16C67.4026 81.0497 69.2375 82.6608 69.2375 84.6299V108.896C69.2375 110.865 67.4253 112.476 65.16 112.476Z"
        fill="#D89979"
      />
      <path
        d="M65.16 110.825H52.9275C50.6849 110.825 48.85 109.214 48.85 107.245V108.896C48.85 110.865 50.6849 112.476 52.9275 112.476H65.16C67.4026 112.476 69.2375 110.865 69.2375 108.896V107.245C69.2375 109.214 67.4253 110.825 65.16 110.825Z"
        fill="#E27444"
      />
      <path
        d="M65.16 81.0497H52.9275C50.6849 81.0497 48.85 82.6608 48.85 84.6299V85.9824C48.85 84.0133 50.6849 82.4022 52.9275 82.4022H65.16C67.4026 82.4022 69.2375 84.0133 69.2375 85.9824V84.6299C69.2375 82.6608 67.4253 81.0497 65.16 81.0497Z"
        fill="#FFB797"
      />
      <path
        d="M65.16 112.476H52.9275C50.6849 112.476 48.85 110.865 48.85 108.896V84.6299C48.85 82.6608 50.6849 81.0497 52.9275 81.0497H65.16C67.4026 81.0497 69.2375 82.6608 69.2375 84.6299V108.896C69.2375 110.865 67.4253 112.476 65.16 112.476Z"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.3"
        d="M59.7233 107.344C62.6258 107.344 64.9788 105.278 64.9788 102.73C64.9788 100.181 62.6258 98.1153 59.7233 98.1153C56.8208 98.1153 54.4679 100.181 54.4679 102.73C54.4679 105.278 56.8208 107.344 59.7233 107.344Z"
        fill="#474747"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.46">
        <path
          opacity="0.46"
          d="M52.9275 110.089C52.18 110.089 51.5684 109.552 51.5684 108.896V84.6299C51.5684 83.9736 52.18 83.4365 52.9275 83.4365H65.16C65.9075 83.4365 66.5192 83.9736 66.5192 84.6299V108.896C66.5192 109.552 65.9075 110.089 65.16 110.089H52.9275Z"
          stroke="#474747"
          strokeWidth="0.273765"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke-dasharray="0.67 0.67"
        />
      </g>
      <path
        d="M59.0438 106.151C61.9463 106.151 64.2993 104.085 64.2993 101.536C64.2993 98.9878 61.9463 96.9219 59.0438 96.9219C56.1413 96.9219 53.7884 98.9878 53.7884 101.536C53.7884 104.085 56.1413 106.151 59.0438 106.151Z"
        fill="#D89979"
      />
      <path
        d="M59.0438 106.151C61.9463 106.151 64.2993 104.085 64.2993 101.536C64.2993 98.9878 61.9463 96.9219 59.0438 96.9219C56.1413 96.9219 53.7884 98.9878 53.7884 101.536C53.7884 104.085 56.1413 106.151 59.0438 106.151Z"
        stroke="#474747"
        strokeWidth="0.410648"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M55.9596 98.7954C56.1318 98.038 57.283 97.9513 58.7822 98.2119C60.2934 98.4987 61.5607 98.9318 61.442 99.5796C61.3322 100.209 59.869 100.936 58.3251 100.767C56.7605 100.589 55.7963 99.5346 55.9596 98.7954Z"
          fill="#FFFCEE"
        />
      </g>
      <g clip-path="url(#clip0_0_1)">
        <path
          d="M26.3356 0C26.3356 0 12.0805 52.7963 26.3356 80.9143C39.9492 107.767 71.9737 101.57 87.3356 127.44C97.6104 144.743 101.836 177 101.836 177"
          stroke="white"
          stroke-opacity="0.22"
        />
        <path
          d="M33.6711 0C33.6711 0 19.4161 52.7963 33.6711 80.9143C47.2847 107.767 79.3092 101.57 94.6711 127.44C104.946 144.743 109.171 177 109.171 177"
          stroke="white"
          stroke-opacity="0.22"
        />
        <path
          d="M41.6711 0C41.6711 0 27.4161 52.7963 41.6711 80.9143C55.2847 107.767 87.3092 101.57 102.671 127.44C112.946 144.743 117.171 177 117.171 177"
          stroke="white"
          stroke-opacity="0.22"
        />
        <path
          d="M48.6711 0C48.6711 0 34.4161 52.7963 48.6711 80.9143C62.2847 107.767 94.3092 101.57 109.671 127.44C119.946 144.743 124.171 177 124.171 177"
          stroke="white"
          stroke-opacity="0.22"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="106"
            height="178"
            fill="white"
            transform="translate(19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CreditPoint = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill={"none"}
    >
      <g transform="translate(0, 0)">
        <circle cx="16" cy="16" r="16" fill={color} fill-opacity="0.15" />
      </g>

      <g transform="translate(8, 8)">
        <path
          d="M12 4L4 12"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 12H4V5.5"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const DebitPoint = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill={"none"}
    >
      <g transform="translate(0, 0)">
        <circle cx="16" cy="16" r="16" fill={color} fill-opacity="0.13" />
      </g>

      <g transform="translate(8, 8)">
        <path
          d="M4 12L12 4"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 4L12 4L12 10.5"
          stroke={color}
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const LoyaltyTag = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11931_187443)">
        <path
          d="M16.2183 15.7484H7.78169C7.38794 15.7484 7.07861 16.0577 7.07861 16.4515V23.2945C7.07861 23.5476 7.21919 23.7866 7.44419 23.9132C7.68316 24.0398 7.95039 24.0257 8.1754 23.885L12 21.3259L15.8246 23.8851C16.0496 24.0257 16.3169 24.0398 16.5558 23.9132C16.7808 23.7866 16.9214 23.5477 16.9214 23.2945V16.4514C16.9214 16.0577 16.6121 15.7484 16.2183 15.7484Z"
          fill="#EE741F"
        ></path>
        <path
          d="M16.9214 16.4514V23.2945C16.9214 23.5476 16.7808 23.7866 16.5558 23.9132C16.3169 24.0398 16.0496 24.0257 15.8246 23.885L12 21.3259V15.7484H16.2183C16.6121 15.7484 16.9214 16.0577 16.9214 16.4514Z"
          fill="#EE741F"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.86035 9.13974C2.86035 4.10578 6.96609 0 12 0C12 0 12.0001 0 12.0001 0C12.6293 1.42598e-06 13.244 0.0641535 13.8381 0.18625C17.5507 0.949337 20.4543 3.97579 21.0341 7.75059C21.1037 8.20373 21.1398 8.66765 21.1398 9.13973C21.1398 14.1736 17.0341 18.2795 12.0001 18.2795V18.2794C12.0001 18.2794 12 18.2794 12 18.2794C6.96609 18.2794 2.86035 14.1737 2.86035 9.13974Z"
          fill="#EE741F"
        ></path>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.22 10.3068L17.3995 8.22568C17.5962 8.0289 17.6666 7.74765 17.5964 7.49448C17.512 7.2414 17.287 7.04452 17.02 7.01635L13.9825 6.58046L12.6327 3.89485C12.5062 3.65579 12.253 3.54333 12 3.54333V3.54343C12 3.54343 11.9999 3.54343 11.9999 3.54343C11.7469 3.54343 11.4937 3.65589 11.3672 3.89495L10.0174 6.58056L6.97994 7.01645C6.71289 7.04462 6.48799 7.2415 6.40357 7.49457C6.33321 7.74765 6.40357 8.0289 6.6003 8.22568L8.7798 10.3068L8.25972 13.2315C8.21749 13.4986 8.32994 13.7658 8.54088 13.9206C8.75168 14.0893 9.04708 14.1034 9.28614 13.9768L12 12.5847L12 12.5847V12.5847L14.7137 13.9768C14.9528 14.1034 15.2482 14.0893 15.459 13.9206C15.6699 13.7658 15.7824 13.4986 15.7401 13.2315L15.22 10.3068Z"
          fill="white"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_11931_187443">
          <rect width="24" height="24" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export const RightArrow = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M504.3 273.6l-112.1 104a24.049 24.049 0 01-25.94 4.406c-8.758-3.812-14.42-12.45-14.42-21.1L351.9 288H32c-17.67 0-32-14.3-32-32.9S14.33 224 32 224h319.9v-72a23.99 23.99 0 0114.42-22c8.754-3.809 18.95-2.075 25.94 4.41l112.1 104c10.24 9.49 10.24 25.69-.06 35.19z"
      ></path>
    </svg>
  );
};

export const LeftArrow = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M512 256c0 17.7-14.3 32-32 32H160.1v72a23.99 23.99 0 01-14.42 22 24.05 24.05 0 01-25.94-4.407l-112.1-104c-10.24-9.5-10.24-25.69 0-35.19l112.1-104a24.049 24.049 0 0125.94-4.406C154.4 133.8 160.1 142.5 160.1 151.1V224H480c17.7 0 32 14.3 32 32z"
      ></path>
    </svg>
  );
};

export const Facebook = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill={color}
        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
      ></path>
    </svg>
  );
};

export const Twitter = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
      ></path>
    </svg>
  );
};

export const LinkedIn = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 01107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
      ></path>
    </svg>
  );
};

export const Youtube = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill={color}
        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
      ></path>
    </svg>
  );
};

export const Instagram = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
      ></path>
    </svg>
  );
};

export const UpArrow = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill={color}
        d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
      />
    </svg>
  );
};

export const DownAngle = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
      ></path>
    </svg>
  );
};

export const GradientStar = ({ fill, size = defaultIconSize }) => {
  const gradientId = `grad${fill}`;

  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset={`${fill}%`}
            style={{ stopColor: "#FAB73B", stopOpacity: 1 }}
          />
          <stop
            offset={`${fill}%`}
            style={{ stopColor: "lightgray", stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <path
        fill={`url(#${gradientId})`}
        d="M316.7 17.8l65.43 132.4 146.4 21.29c26.27 3.796 36.79 36.09 17.75 54.59l-105.9 102.1 25.05 145.5c4.508 26.31-23.23 45.9-46.49 33.7L288 439.6l-130.9 68.7c-23.3 12.2-51-7.4-46.5-33.7l25.05-145.5-105.93-103c-19.03-18.5-8.516-50.79 17.75-54.59l146.4-21.29 65.43-132.4c11.8-23.903 45.7-23.606 57.4-.02z"
      ></path>
    </svg>
  );
};

export const Star = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill={color}
        d="M316.7 17.8l65.43 132.4 146.4 21.29c26.27 3.796 36.79 36.09 17.75 54.59l-105.9 102.1 25.05 145.5c4.508 26.31-23.23 45.9-46.49 33.7L288 439.6l-130.9 68.7c-23.3 12.2-51-7.4-46.5-33.7l25.05-145.5-105.93-103c-19.03-18.5-8.516-50.79 17.75-54.59l146.4-21.29 65.43-132.4c11.8-23.903 45.7-23.606 57.4-.02z"
      ></path>
    </svg>
  );
};

export const Close = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
      />
    </svg>
  );
};

export const GiftBox = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "translate(-5px, 2px)", marginRight: "3px" }}
    >
      <path
        d="M6 9C6 7.34315 7.34315 6 9 6H24H39C40.6569 6 42 7.34315 42 9V24V39C42 40.6569 40.6569 42 39 42H24H9C7.34315 42 6 40.6569 6 39V24V9Z"
        fill="none"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 24H44"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 44V4"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 30V18"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42 30V18"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 42H18"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 6H18"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0001 23.9999C24.0001 23.9999 31.8972 20.4542 33.0988 19.2526C34.3004 18.051 34.3004 16.1028 33.0988 14.9012C31.8972 13.6996 29.9489 13.6996 28.7473 14.9012C27.5457 16.1028 24.0001 23.9999 24.0001 23.9999ZM24.0001 23.9999C24.0001 23.9999 16.1027 20.4542 14.901 19.2526M24.0001 23.9999C24.0001 23.9999 20.4541 16.1028 19.2525 14.9012M24.0001 23.9999C24.0001 23.9999 31.897 27.5458 33.0986 28.7474M24.0001 23.9999C24.0001 23.9999 27.5455 31.8972 28.7472 33.0988M24.0001 23.9999C24.0001 23.9999 16.1028 27.5458 14.9012 28.7474C13.6996 29.949 13.6996 31.8972 14.9012 33.0988C16.1028 34.3004 18.0511 34.3004 19.2527 33.0988C20.4543 31.8972 24.0001 23.9999 24.0001 23.9999Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseIcon = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M353.7 158.3l-22.4-22.4-75.8 75.8-75.8-75.8-22.4 22.4 75.8 75.8-75.8 75.8 22.4 22.4 75.8-75.8 75.8 75.8 22.4-22.4-75.8-75.8 75.8-75.8z"
      />
    </svg>
  );
};

export const Clock = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"
      />
    </svg>
  );
};

export default function Checkmark() {
  return (
    <div>
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
}

export const Discount = ({
  size = defaultIconSize,
  color = defaultIconColor,
  ...rest
}) => {
  return (
    <svg
      {...rest}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill={color}
        d="M543.8 256c0-36.5-18.86-68.38-46.86-86.75c6.875-32.88-2.517-68.63-28.14-94.25c-25.62-25.75-61.61-35.13-94.36-28.25C355.1 18.75 324.1 0 287.8 0S219.5 18.75 200.1 46.75C168.1 39.88 132.5 49.38 106.8 75C81.09 100.6 71.61 136.5 78.48 169.3C50.36 187.8 31.84 219.8 31.84 256s18.64 68.25 46.64 86.75C71.61 375.6 81.21 411.4 106.8 437c25.62 25.75 61.14 35.13 94.14 28.25C219.5 493.4 251.6 512 287.8 512c36.38 0 68.14-18.75 86.64-46.75c33 6.875 68.73-2.625 94.36-28.25c25.75-25.62 35.02-61.5 28.14-94.25C525.1 324.3 543.8 292.3 543.8 256zM223.8 160c17.62 0 31.1 14.38 31.1 32S241.4 224 223.8 224s-32-14.38-32-32S206.2 160 223.8 160zM240.1 336.1C236.3 341.7 230.2 344 224 344s-12.28-2.344-16.97-7.031c-9.375-9.375-9.375-24.56 0-33.94l128-128c9.375-9.375 24.56-9.375 33.94 0s9.375 24.56 0 33.94L240.1 336.1zM351.8 352c-17.62 0-32-14.38-32-32s14.38-32 32-32s32 14.38 32 32S369.4 352 351.8 352z"
      />
    </svg>
  );
};

export const FreeTag = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      id="Layer_10"
      data-name="Layer 10"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 192 192"
    >
      <path
        className="cls-1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        d="M166.23,87.61c2.85,2.59,5.16,5.3,5.16,8.39,0,7.45-13.44,12.69-15.63,19.42-2.26,7,5.45,19.09,1.23,24.9s-18.21,2.25-24.07,6.51-6.65,18.62-13.62,20.88c-6.73,2.19-15.85-8.89-23.3-8.89s-16.57,11.08-23.3,8.89c-7-2.26-7.82-16.66-13.62-20.88s-19.81-.65-24.07-6.51,3.49-17.93,1.23-24.9c-2.19-6.73-15.63-12-15.63-19.42S34.05,83.31,36.24,76.58c2.26-7-5.45-19.09-1.23-24.9s18.21-2.25,24.07-6.51S65.73,26.55,72.7,24.29c6.73-2.19,15.85,8.89,23.3,8.89s16.57-11.08,23.3-8.89c7,2.26,7.82,16.66,13.62,20.88s19.81.65,24.07,6.51c2.53,3.48.77,9.24-.55,14.79"
      ></path>
      <polyline
        className="cls-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        points="49.1 107.66 49.1 84.34 64.48 84.34"
      ></polyline>
      <line
        className="cls-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        x1="62.35"
        y1="96.4"
        x2="49.26"
        y2="96.4"
      ></line>
      <polyline
        className="cls-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        points="117.19 84.34 101.81 84.34 101.81 107.66 117.19 107.66"
      ></polyline>
      <line
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        className="cls-2"
        x1="115.05"
        y1="96.4"
        x2="101.96"
        y2="96.4"
      ></line>
      <polyline
        className="cls-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        points="142.9 84.34 127.52 84.34 127.52 107.66 142.9 107.66"
      ></polyline>
      <line
        className="cls-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        x1="140.76"
        y1="96.4"
        x2="127.68"
        y2="96.4"
      ></line>
      <path
        className="cls-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        d="M75.05,96.4h8.86a5.74,5.74,0,0,1,5.74,5.73v5.53"
      ></path>
      <path
        className="cls-2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
        stroke={color}
        d="M74.14,96.4h10a6,6,0,0,0,6-6h0a6,6,0,0,0-6-6H74v23.32"
      ></path>
    </svg>
  );
};

export const CheckBadge = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M512 256c0-37.7-23.7-69.9-57.1-82.4 14.7-32.4 8.8-71.9-17.9-98.6-26.7-26.7-66.2-32.6-98.6-17.9C325.9 23.7 293.7 0 256 0s-69.9 23.7-82.4 57.1c-32.4-14.7-72-8.8-98.6 17.9-26.7 26.7-32.6 66.2-17.9 98.6C23.7 186.1 0 218.3 0 256s23.7 69.9 57.1 82.4c-14.7 32.4-8.8 72 17.9 98.6 26.6 26.6 66.1 32.7 98.6 17.9 12.5 33.3 44.7 57.1 82.4 57.1s69.9-23.7 82.4-57.1c32.6 14.8 72 8.7 98.6-17.9 26.7-26.7 32.6-66.2 17.9-98.6 33.4-12.5 57.1-44.7 57.1-82.4zm-144.8-44.25L236.16 341.74c-4.31 4.28-11.28 4.25-15.55-.06l-75.72-76.33c-4.28-4.31-4.25-11.28.06-15.56l26.03-25.82c4.31-4.28 11.28-4.25 15.56.06l42.15 42.49 97.2-96.42c4.31-4.28 11.28-4.25 15.55.06l25.82 26.03c4.28 4.32 4.26 11.29-.06 15.56z"
      />
    </svg>
  );
};

export const House = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill={color}
        d="M208 216v79.1c0 22.06 17.94 39.1 39.1 39.1h79.1c22.06 0 39.1-17.94 39.1-39.1V216c0-22.06-17.94-39.1-39.1-39.1H248c-22.1-.9-40 17-40 39.1zm48 8h63.1v63.1H256V224zm319.1 23.1c0-6.802-2.877-13.56-8.471-18.31l-55.53-47.12V55.99c0-13.25-10.75-24-23.1-24s-23.1 10.75-23.1 24v85.85l-160.5-136.2c-4.484-3.781-10.01-5.669-15.53-5.669S276.1 1.891 272.5 5.672L8.472 229.7c-5.594 4.7-8.47 11.5-8.47 17.4 0 16.03 13.69 24 24.01 24 5.483 0 10.1-1.867 15.52-5.687l24.47-20.76.01 202.5c0 35.35 28.65 63.1 63.1 63.1h319.1c35.35 0 63.1-28.65 63.1-63.1v-202.5l24.47 20.76a23.994 23.994 0 0015.52 5.688C562.1 271.1 575.1 263.1 575.1 247.1zm-112 200c0 8.799-7.199 16-15.1 16H128c-8.801 0-15.1-7.201-15.1-16v-240c0-.963-.438-1.783-.549-2.717L288 55.46 463.1 204.8v242.3z"
      ></path>
    </svg>
  );
};

export const RightAngle = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill={color}
        d="M113.3 47.41l183.1 191.1c4.469 4.625 6.688 10.62 6.688 16.59s-2.219 11.97-6.688 16.59l-183.1 191.1c-9.152 9.594-24.34 9.906-33.9.719-9.625-9.125-9.938-24.38-.719-33.91l168-175.4L78.71 80.6c-9.219-9.5-8.906-24.78.719-33.91 9.561-9.19 24.771-8.87 33.871.72z"
      ></path>
    </svg>
  );
};

export const LeftAngle = ({ size = 22, color = 22 }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill={color}
        d="M206.7 464.6L23.6 273.5c-5.38-6.4-7.6-12.4-7.6-17.5s2.219-11.97 6.688-16.59l183.1-191.1c9.152-9.594 24.34-9.906 33.9-.719 9.625 9.125 9.938 24.37.719 33.91L73.24 256l168 175.4c9.219 9.5 8.906 24.78-.719 33.91-9.521 9.19-24.721 8.89-33.821-.71z"
      ></path>
    </svg>
  );
};

export const CouponTag = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.59 13.41L13.42 20.58C13.2343 20.766 13.0137 20.9135 12.7709 21.0141C12.5281 21.1148 12.2678 21.1666 12.005 21.1666C11.7422 21.1666 11.4819 21.1148 11.2391 21.0141C10.9963 20.9135 10.7757 20.766 10.59 20.58L2 12V2H12L20.59 10.59C20.9625 10.9647 21.1716 11.4716 21.1716 12C21.1716 12.5284 20.9625 13.0353 20.59 13.41Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 7H7.01"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const UpAngle = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M15.41 302.7l191.1-183.1c5.49-5.4 11.49-8.5 17.49-8.5s11.97 2.219 16.59 6.688l191.1 183.1c9.594 9.152 9.906 24.34.719 33.9-9.125 9.625-24.38 9.938-33.91.719L224 169.2l-175.4 168c-9.5 9.219-24.78 8.906-33.91-.719C5.502 327 5.814 311.8 15.41 302.7z"
      ></path>
    </svg>
  );
};

export const FullSreen = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M136 32H24C10.75 32 0 42.75 0 56v112c0 13.3 10.75 24 24 24 13.26 0 24-10.7 24-24V80h88c13.3 0 24-10.75 24-24s-10.7-24-24-24zm288 0H312c-13.3 0-24 10.75-24 24 0 13.26 10.75 24 24 24h88v88c0 13.3 10.7 24 24 24s24-10.7 24-24V56c0-13.25-10.7-24-24-24zM136 432H48v-88c0-13.3-10.75-24-24-24S0 330.7 0 344v112c0 13.3 10.75 24 24 24h112c13.3 0 24-10.7 24-24s-10.7-24-24-24zm288-112c-13.26 0-24 10.75-24 24v88h-88c-13.26 0-24 10.75-24 24s10.7 24 24 24h112c13.25 0 24-10.75 24-24V344c0-13.3-10.7-24-24-24z"
      ></path>
    </svg>
  );
};
export const Share = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_1714)">
        <rect x="4" y="2" width="36" height="36" rx="18" fill="white" />
        <path
          d="M12.4525 28.6549C12.3735 28.6553 12.2949 28.6426 12.22 28.6174C12.0634 28.5665 11.9278 28.4655 11.8343 28.33C11.7408 28.1944 11.6944 28.0319 11.7025 27.8674C11.7025 27.7549 12.475 16.8649 23.395 16.0099V12.0949C23.3949 11.9458 23.4392 11.8 23.5223 11.6762C23.6054 11.5524 23.7235 11.4562 23.8616 11.3999C23.9996 11.3435 24.1513 11.3296 24.2973 11.3599C24.4433 11.3902 24.577 11.4633 24.6812 11.5699L32.0837 19.1299C32.2212 19.2701 32.2981 19.4586 32.2981 19.6549C32.2981 19.8512 32.2212 20.0397 32.0837 20.1799L24.6812 27.7399C24.577 27.8465 24.4433 27.9196 24.2973 27.9499C24.1513 27.9802 23.9996 27.9663 23.8616 27.9099C23.7235 27.8536 23.6054 27.7574 23.5223 27.6336C23.4392 27.5098 23.3949 27.364 23.395 27.2149V23.3749C16.105 23.6524 13.1162 28.2499 13.0862 28.3061C13.0186 28.413 12.925 28.501 12.8142 28.562C12.7034 28.6229 12.579 28.6549 12.4525 28.6549ZM24.895 13.9324V16.7111C24.8951 16.9056 24.8197 17.0925 24.6847 17.2324C24.5497 17.3723 24.3656 17.4543 24.1712 17.4611C17.1062 17.7199 14.5787 22.4261 13.6712 25.4524C15.5462 23.8324 18.8875 21.8524 24.1 21.8524H24.1337C24.3327 21.8524 24.5234 21.9314 24.6641 22.0721C24.8047 22.2127 24.8837 22.4035 24.8837 22.6024V25.3811L30.5087 19.6586L24.895 13.9324Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_1714"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.101961 0 0 0 0 0.0941176 0 0 0 0 0.117647 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_1714"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_1714"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const Lock = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M384 192h-32v-64.9C352 56.52 294.58 0 224 0S96 57.42 96 127.1V192H64c-35.35 0-64 28.7-64 64v191.1c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V256c0-35.3-28.7-64-64-64zm-240-64c0-44.11 35.89-80 80-80s80 35.89 80 80v64H144v-64zm256 320c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V256c0-8.822 7.178-16 16-16h320c8.822 0 16 7.178 16 16v192z"
      ></path>
    </svg>
  );
};
export const Copy = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM352 32.491a15.88 15.88 0 0 1 7.431 4.195l51.882 51.883A15.885 15.885 0 0 1 415.508 96H352V32.491zM288 464c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h80v240c0 26.51 21.49 48 48 48h112v48zm128-96c0 8.822-7.178 16-16 16H176c-8.822 0-16-7.178-16-16V48c0-8.822 7.178-16 16-16h144v72c0 13.2 10.8 24 24 24h72v240z"
      />
    </svg>
  );
};

export const Cash = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M325.1 115.9l55.37-81.13c3.75-7.5 3.375-16.25-1-23.38C375.1 4.375 368.2 0 359.1 0h-208c-8.375 0-16 4.25-20.38 11.38s-4.02 15.87-.22 23.37l55.5 81.13C-9.918 235.6.083 392 .083 412c0 55.25 49.14 100 109.6 100h292.5c60.62 0 109.7-44.75 109.7-100 .017-19.6 8.817-177-186.783-296.1zM314.2 48l-38.25 56h-40L197.7 48h116.5zm88 416H109.7c-34 0-61.63-23.38-61.63-52-.86-81 32.64-175.5 170.03-260h74.13c137.5 84.13 171.5 178.9 170.9 260-.03 28.6-26.93 52-60.93 52zm-75.1-104.2c-4.139 23.88-21.77 40.01-47 45.74V416c0 13.25-10.75 24-24 24s-24-10.75-24-24v-11.4c-9.527-2.219-18.95-5.254-27.44-8.316l-4.656-1.656c-12.47-4.438-19.03-18.16-14.59-30.64s18.12-19 30.66-14.61l4.844 1.75c8.812 3.172 18.78 6.766 26.5 7.922 16.97 2.719 31.19-.61 32.38-7.391.813-4.703 1.312-7.547-30.38-16.61l-6.5-1.875c-18.19-5.359-66.44-19.55-57.91-69.02C189.1 240.3 206.8 224.2 232.1 218.5V208c0-13.25 10.75-24 24-24s24 10.75 24 24v11.25c5.916 1.365 12.23 3.096 19.81 5.732 12.53 4.344 19.16 18.02 14.81 30.55-4.375 12.52-18.12 19.19-30.56 14.8-8.219-2.859-14.62-4.625-19.59-5.406-16.97-2.626-31.07.574-32.27 7.374-.7 4.1-1.3 7.3 24.1 14.8l6.188 1.797C288.1 296.2 335.7 309.8 327.1 359.8z"
      ></path>
    </svg>
  );
};

export const Confetti = ({}) => {
  return (
    <div>
      <div className="confetti">
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
        <div className="confetti-piece"></div>
      </div>
    </div>
  );
};

export const Return = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M454.7 288.1c-12.78-3.75-26.06 3.594-29.75 16.31C403.3 379.9 333.8 432 255.1 432c-66.53 0-126.8-38.28-156.5-96H199c13.25 0 24-10.75 24-24s-9.8-24-23.1-24h-160c-13.25 0-24 10.75-24 24v160c0 13.25 10.75 24 24 24s24-10.75 24-24V369.9C103.7 436.4 176.1 480 255.1 480c99 0 187.4-66.31 215.1-161.3 4.6-13.6-2.8-26-15.5-30.6zM472 16c-13.2 0-24 10.75-24 24v102.1C408.3 75.55 335.8 32 256 32 157 32 68.53 98.31 40.91 193.3c-3.72 12.7 3.59 26 16.31 29.7 12.84 3.781 26.09-3.625 29.75-16.31C108.7 132.1 178.2 80 256 80c66.53 0 126.8 38.28 156.5 96H312c-13.2 0-24 10.8-24 24s10.8 24 24 24h160c13.25 0 24-10.75 24-24V40c0-13.25-10.7-24-24-24z"
      ></path>
    </svg>
  );
};

export const MagnifyingGlass = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M504.1 471l-134-134C399.1 301.5 415.1 256.8 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c48.79 0 93.55-16.91 129-45.04l134 134C475.7 509.7 481.9 512 488 512s12.28-2.344 16.97-7.031C514.3 495.6 514.3 480.4 504.1 471zM48 208c0-88.22 71.78-160 160-160s160 71.78 160 160s-71.78 160-160 160S48 296.2 48 208z"
      />
    </svg>
  );
};

export const MagnifyingGlassThin = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M509.7 498.3L360.5 349.1c34.3-37 54.6-86.5 54.6-141.1 0-114.9-93.13-208-208-208S0 93.13 0 208s93.12 208 207.1 208c54.55 0 104.1-21.17 141.2-55.54l149.2 149.2c2.4 1.54 4.4 2.34 5.6 2.34s4.094-.781 5.656-2.344c4.044-3.156 4.044-8.156.944-11.356zM207.1 400c-105.9 0-192-86.13-192-192s86.13-192 192-192 192 86.13 192 192-85.2 192-192 192z"
      ></path>
    </svg>
  );
};

export const Minus = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M432 256c0 13.3-10.7 24-24 24H40c-13.25 0-24-10.74-24-23.99C16 242.8 26.75 232 40 232h368c13.3 0 24 10.8 24 24z"
      ></path>
    </svg>
  );
};

export const Plus = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M432 256c0 13.3-10.7 24-24 24H248v160c0 13.25-10.75 24.01-24 24.01S200 453.3 200 440V280H40c-13.25 0-24-10.74-24-23.99C16 242.8 26.75 232 40 232h160V72c0-13.25 10.75-23.99 24-23.99S248 58.75 248 72v160h160c13.3 0 24 10.8 24 24z"
      ></path>
    </svg>
  );
};

export const Cross = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill={color}
        d="M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z"
      />
    </svg>
  );
};

export const Grid = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M96 0H32C14.33 0 0 14.33 0 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V32c0-17.67-14.3-32-32-32zm192 0h-64c-17.7 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V32c0-17.67-14.3-32-32-32zm192 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V32c0-17.67-14.3-32-32-32zM96 384H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.7-14.3-32-32-32zm192 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.7-14.3-32-32-32zm192 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.7-14.3-32-32-32zM96 192H32c-17.67 0-32 14.3-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.7-14.3-32-32-32zm192 0h-64c-17.7 0-32 14.3-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.7-14.3-32-32-32zm192 0h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.7-14.3-32-32-32z"
      ></path>
    </svg>
  );
};

export const List = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M80 48H16C7.156 48 0 55.16 0 64v64c0 8.844 7.156 16 16 16h64c8.84 0 16-7.2 16-16V64c0-8.84-7.16-16-16-16zm0 160H16c-8.844 0-16 7.2-16 16v64c0 8.844 7.156 16 16 16h64c8.84 0 16-7.2 16-16v-64c0-8.8-7.16-16-16-16zm0 160H16c-8.844 0-16 7.2-16 16v64c0 8.844 7.156 16 16 16h64c8.84 0 16-7.2 16-16v-64c0-8.8-7.16-16-16-16zm112-240h288c17.67 0 32-14.33 32-31.1S497.7 64 480 64H192c-17.7 0-32 14.33-32 31.1s14.3 32.9 32 32.9zm288 256H192c-17.67 0-32 14.33-32 32s14.33 32 32 32h288c17.67 0 32-14.33 32-32s-14.3-32-32-32zm0-160H192c-17.7 0-32 14.3-32 32s14.33 32 32 32h288c17.67 0 32-14.33 32-32s-14.3-32-32-32z"
      ></path>
    </svg>
  );
};

export const Menu = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M31.1 128H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H31.1C14.33 64 0 78.33 0 95.1S14.33 128 31.1 128zM480 224H95.1C78.33 224 64 238.3 64 256s14.33 32 31.1 32h384c18.6 0 32.9-14.3 32.9-32s-14.3-32-32-32zm-64 160H31.1C14.33 384 0 398.3 0 415.1S14.33 448 31.1 448H416c17.7 0 32-14.3 32-32s-14.3-32-32-32z"
      ></path>
    </svg>
  );
};

export const ShoppingCart = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill={color}
        d="M175.1 416c-26.51 0-47.1 21.49-47.1 48S149.5 512 175.1 512s47.1-21.49 47.1-48S202.5 416 175.1 416zM463.1 416c-26.51 0-47.1 21.49-47.1 48s21.49 48 47.1 48s47.1-21.49 47.1-48S490.5 416 463.1 416zM569.5 44.73c-6.109-8.094-15.42-12.73-25.56-12.73H121.1L119.6 19.51C117.4 8.189 107.5 0 96 0H23.1C10.75 0 0 10.74 0 23.1C0 37.25 10.75 48 23.1 48h52.14l60.28 316.5C138.6 375.8 148.5 384 160 384H488c13.25 0 24-10.75 24-23.1C512 346.7 501.3 336 488 336H179.9L170.7 288h318.4c14.28 0 26.84-9.479 30.77-23.21l54.86-191.1C577.5 63.05 575.6 52.83 569.5 44.73zM477 240H161.6l-30.47-160h391.7L477 240z"
      />
    </svg>
  );
};

export const Cart = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 6H21"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const Delete = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.00037H4.16667H17.5"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8334 5.00033V16.667C15.8334 17.109 15.6578 17.5329 15.3453 17.8455C15.0327 18.1581 14.6088 18.3337 14.1667 18.3337H5.83341C5.39139 18.3337 4.96746 18.1581 4.6549 17.8455C4.34234 17.5329 4.16675 17.109 4.16675 16.667V5.00033M6.66675 5.00033V3.33366C6.66675 2.89163 6.84234 2.46771 7.1549 2.15515C7.46746 1.84259 7.89139 1.66699 8.33341 1.66699H11.6667C12.1088 1.66699 12.5327 1.84259 12.8453 2.15515C13.1578 2.46771 13.3334 2.89163 13.3334 3.33366V5.00033"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33325 9.16699V14.167"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 9.16699V14.167"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const LimitedTimeDiscount = ({ discountAmount, size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.1867 29.6667C45.68 27.8333 48 25.9 48 24C48 22.1 45.68 20.1667 45.1867 18.3333C44.6933 16.5 45.74 13.6467 44.7933 12C43.84 10.3533 40.8467 9.84667 39.5 8.5C38.1533 7.15333 37.6467 4.16 36 3.20667C34.3533 2.25333 31.5 3.3 29.6667 2.81333C27.8333 2.32667 25.9 0 24 0C22.1 0 20.1667 2.32 18.3333 2.81333C16.5 3.30667 13.6467 2.26 12 3.20667C10.3533 4.15333 9.84667 7.15333 8.5 8.5C7.15333 9.84667 4.16 10.3533 3.20667 12C2.25333 13.6467 3.3 16.5 2.81333 18.3333C2.32667 20.1667 0 22.1 0 24C0 25.9 2.32 27.8333 2.81333 29.6667C3.30667 31.5 2.26 34.3533 3.20667 36C4.16 37.6467 7.15333 38.1533 8.5 39.5C9.84667 40.8467 10.3533 43.84 12 44.7933C13.6467 45.7467 16.5 44.7 18.3333 45.1867C20.1667 45.68 22.1 48 24 48C25.9 48 27.8333 45.68 29.6667 45.1867C31.5 44.6933 34.3533 45.74 36 44.7933C37.6467 43.84 38.1533 40.8467 39.5 39.5C40.8467 38.1533 43.84 37.6467 44.7933 36C45.7467 34.3533 44.7 31.5 45.1867 29.6667Z"
        fill="#CB0518"
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        fill="white"
        fontSize="12"
        fontWeight="bold"
      >
        {discountAmount}%
      </text>
      <text
        x="50%"
        y="78%"
        textAnchor="middle"
        fill="white"
        fontSize="12"
        fontWeight="bold"
      >
        OFF
      </text>
    </svg>
  );
};

export const Check = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11.0799V11.9999C21.9988 14.1563 21.3005 16.2545 20.0093 17.9817C18.7182 19.7088 16.9033 20.9723 14.8354 21.5838C12.7674 22.1952 10.5573 22.1218 8.53447 21.3744C6.51168 20.6271 4.78465 19.246 3.61096 17.4369C2.43727 15.6279 1.87979 13.4879 2.02168 11.3362C2.16356 9.18443 2.99721 7.13619 4.39828 5.49694C5.79935 3.85768 7.69279 2.71525 9.79619 2.24001C11.8996 1.76477 14.1003 1.9822 16.07 2.85986"
        stroke="#17B31B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 4L12 14.01L9 11.01"
        stroke="#17B31B"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const Free = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.1867 29.6667C45.68 27.8333 48 25.9 48 24C48 22.1 45.68 20.1667 45.1867 18.3333C44.6933 16.5 45.74 13.6467 44.7933 12C43.84 10.3533 40.8467 9.84667 39.5 8.5C38.1533 7.15333 37.6467 4.16 36 3.20667C34.3533 2.25333 31.5 3.3 29.6667 2.81333C27.8333 2.32667 25.9 0 24 0C22.1 0 20.1667 2.32 18.3333 2.81333C16.5 3.30667 13.6467 2.26 12 3.20667C10.3533 4.15333 9.84667 7.15333 8.5 8.5C7.15333 9.84667 4.16 10.3533 3.20667 12C2.25333 13.6467 3.3 16.5 2.81333 18.3333C2.32667 20.1667 0 22.1 0 24C0 25.9 2.32 27.8333 2.81333 29.6667C3.30667 31.5 2.26 34.3533 3.20667 36C4.16 37.6467 7.15333 38.1533 8.5 39.5C9.84667 40.8467 10.3533 43.84 12 44.7933C13.6467 45.7467 16.5 44.7 18.3333 45.1867C20.1667 45.68 22.1 48 24 48C25.9 48 27.8333 45.68 29.6667 45.1867C31.5 44.6933 34.3533 45.74 36 44.7933C37.6467 43.84 38.1533 40.8467 39.5 39.5C40.8467 38.1533 43.84 37.6467 44.7933 36C45.7467 34.3533 44.7 31.5 45.1867 29.6667Z"
        fill="#17B31B"
      />
      <path
        d="M11.0607 29V20.36H16.3407V21.992H12.6927V23.864H15.6207V25.496H12.6927V29H11.0607ZM17.424 29V20.36H21.072C21.156 20.36 21.268 20.364 21.408 20.372C21.552 20.376 21.68 20.388 21.792 20.408C22.308 20.488 22.73 20.658 23.058 20.918C23.39 21.178 23.634 21.506 23.79 21.902C23.946 22.294 24.024 22.732 24.024 23.216C24.024 23.94 23.844 24.56 23.484 25.076C23.124 25.588 22.56 25.904 21.792 26.024L21.072 26.072H19.056V29H17.424ZM22.296 29L20.592 25.484L22.272 25.16L24.144 29H22.296ZM19.056 24.548H21C21.084 24.548 21.176 24.544 21.276 24.536C21.376 24.528 21.468 24.512 21.552 24.488C21.772 24.428 21.942 24.328 22.062 24.188C22.182 24.044 22.264 23.886 22.308 23.714C22.356 23.538 22.38 23.372 22.38 23.216C22.38 23.06 22.356 22.896 22.308 22.724C22.264 22.548 22.182 22.39 22.062 22.25C21.942 22.106 21.772 22.004 21.552 21.944C21.468 21.92 21.376 21.904 21.276 21.896C21.176 21.888 21.084 21.884 21 21.884H19.056V24.548ZM25.463 29V20.36H31.103V21.884H27.095V23.732H30.383V25.256H27.095V27.476H31.103V29H25.463ZM32.5412 29V20.36H38.1812V21.884H34.1732V23.732H37.4612V25.256H34.1732V27.476H38.1812V29H32.5412Z"
        fill="white"
      />
    </svg>
  );
};

export const LocationDot = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill={color}
        d="M192 0C85.97 0 0 85.97 0 192c0 77.41 26.97 99.03 172.3 309.7C177 508.6 184.5 512 192 512s14.95-3.441 19.72-10.32C357 291 384 269.4 384 192C384 85.97 298 0 192 0zM192 445.8c-17.69-25.46-33.42-47.87-47.38-67.75C57.56 254.1 48 238.9 48 192c0-79.4 64.6-144 144-144s144 64.6 144 144c0 46.88-9.562 62.07-96.62 186.1C225.4 397.1 209.7 420.4 192 445.8zM192 111.1c-44.18 0-80 35.82-80 80S147.8 271.1 192 271.1c44.18 0 80-35.82 80-80S236.2 111.1 192 111.1zM192 224C174.4 224 160 209.6 160 192s14.36-32 32-32s32 14.36 32 32S209.6 224 192 224z"
      />
    </svg>
  );
};

export const ListRadio = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M184 120h304C501.3 120 512 109.3 512 96s-10.75-24-24-24h-304C170.8 72 160 82.75 160 96S170.8 120 184 120zM488 392h-304C170.8 392 160 402.8 160 416s10.75 24 24 24h304c13.25 0 24-10.75 24-24S501.3 392 488 392zM488 232h-304C170.8 232 160 242.8 160 256s10.75 24 24 24h304C501.3 280 512 269.3 512 256S501.3 232 488 232zM99.78 42.93C89.57 36.03 77.25 32 64 32C28.65 32 0 60.66 0 96s28.65 64 64 64c13.25 0 25.57-4.029 35.78-10.93c6.811-4.6 12.69-10.48 17.29-17.29C123.1 121.6 128 109.3 128 96S123.1 70.43 117.1 60.22C112.5 53.41 106.6 47.53 99.78 42.93zM64 192C28.65 192 0 220.7 0 256s28.65 64 64 64c35.35 0 64-28.66 64-64S99.35 192 64 192zM64 280C50.77 280 40 269.2 40 256c0-13.23 10.77-24 24-24c13.23 0 24 10.77 24 24C88 269.2 77.23 280 64 280zM64 352c-35.35 0-64 28.66-64 64s28.65 64 64 64c35.35 0 64-28.66 64-64S99.35 352 64 352zM64 440c-13.23 0-24-10.77-24-24c0-13.23 10.77-24 24-24c13.23 0 24 10.77 24 24C88 429.2 77.23 440 64 440z"
      />
    </svg>
  );
};

export const ArrowRightFromBracket = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M192 456C192 469.3 181.3 480 168 480H96c-53.02 0-96-42.98-96-96V128c0-53.02 42.98-96 96-96h72C181.3 32 192 42.74 192 56C192 69.25 181.3 80 168 80H96C69.6 80 48 101.6 48 128v256c0 26.4 21.6 48 48 48h72C181.3 432 192 442.7 192 456zM505.5 239.6l-127.1-136c-9.094-9.688-24.28-10.12-33.91-1.031c-9.656 9.062-10.12 24.25-1.031 33.91L432.4 232H183.1C170.7 232 160 242.8 160 256s10.75 24 23.1 24h248.4l-89.92 95.56c-9.094 9.656-8.625 24.84 1.031 33.91C348.2 413.8 354.1 416 359.1 416c6.375 0 12.75-2.531 17.47-7.562l127.1-136C514.2 263.2 514.2 248.8 505.5 239.6z"
      />
    </svg>
  );
};

export const ArrowLeft = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill={color}
        d="M447.1 256c0 13.25-10.76 24.01-24.01 24.01H83.9l132.7 126.6c9.625 9.156 9.969 24.41 .8125 33.94c-9.156 9.594-24.34 9.938-33.94 .8125l-176-168C2.695 268.9 .0078 262.6 .0078 256S2.695 243.2 7.445 238.6l176-168C193 61.51 208.2 61.85 217.4 71.45c9.156 9.5 8.812 24.75-.8125 33.94l-132.7 126.6h340.1C437.2 232 447.1 242.8 447.1 256z"
      />
    </svg>
  );
};

export const Cricle = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480z"
      />
    </svg>
  );
};

export const CricleDot = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={color}
        d="M256 160C203 160 160 203 160 256c0 52.95 43.05 96 96 96s96-43.05 96-96C352 203 308.1 160 256 160zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z"
      />
    </svg>
  );
};

export const Pinterest = ({
  size = defaultIconSize,
  color = defaultIconColor,
}) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill={color}
        d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z"
      />
    </svg>
  );
};

export const Eye = ({ size = defaultIconSize, color = defaultIconColor }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill={color}
        d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
      />
    </svg>
  );
};

export const FacebookHeaderIcon = ({ color = "#4d4d4d" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0,0,256,256"
    >
      <g
        fill={color}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: "normal" }}
      >
        <g transform="scale(10.66667,10.66667)">
          <path d="M12,2c-5.523,0 -10,4.477 -10,10c0,5.013 3.693,9.153 8.505,9.876v-7.226h-2.474v-2.629h2.474v-1.749c0,-2.896 1.411,-4.167 3.818,-4.167c1.153,0 1.762,0.085 2.051,0.124v2.294h-1.642c-1.022,0 -1.379,0.969 -1.379,2.061v1.437h2.995l-0.406,2.629h-2.588v7.247c4.881,-0.661 8.646,-4.835 8.646,-9.897c0,-5.523 -4.477,-10 -10,-10z"></path>
        </g>
      </g>
    </svg>
  );
};

export const InstagramHeaderIcon = ({ color = "#4d4d4d" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0,0,256,256"
    >
      <g
        fill={color}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: "normal" }}
      >
        <g transform="scale(10.66667,10.66667)">
          <path d="M8,3c-2.761,0 -5,2.239 -5,5v8c0,2.761 2.239,5 5,5h8c2.761,0 5,-2.239 5,-5v-8c0,-2.761 -2.239,-5 -5,-5zM18,5c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1zM12,7c2.761,0 5,2.239 5,5c0,2.761 -2.239,5 -5,5c-2.761,0 -5,-2.239 -5,-5c0,-2.761 2.239,-5 5,-5zM12,9c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3z"></path>
        </g>
      </g>
    </svg>
  );
};

export const PinterestHeaderIcon = ({ color = "#4d4d4d" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0,0,256,256"
    >
      <g
        fill={color}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: "normal" }}
      >
        <g transform="scale(10.66667,10.66667)">
          <path d="M12,2c-5.523,0 -10,4.477 -10,10c0,4.237 2.636,7.855 6.356,9.312c-0.087,-0.791 -0.167,-2.005 0.035,-2.868c0.182,-0.78 1.172,-4.971 1.172,-4.971c0,0 -0.299,-0.599 -0.299,-1.484c0,-1.391 0.806,-2.428 1.809,-2.428c0.853,0 1.265,0.641 1.265,1.408c0,0.858 -0.546,2.141 -0.828,3.329c-0.236,0.996 0.499,1.807 1.481,1.807c1.777,0 3.143,-1.874 3.143,-4.579c0,-2.394 -1.72,-4.068 -4.177,-4.068c-2.845,0 -4.515,2.134 -4.515,4.34c0,0.859 0.331,1.781 0.744,2.282c0.082,0.099 0.093,0.186 0.069,0.287c-0.076,0.316 -0.244,0.995 -0.277,1.134c-0.043,0.183 -0.145,0.222 -0.334,0.133c-1.249,-0.582 -2.03,-2.408 -2.03,-3.874c0,-3.154 2.292,-6.052 6.608,-6.052c3.469,0 6.165,2.472 6.165,5.776c0,3.447 -2.173,6.22 -5.189,6.22c-1.013,0 -1.966,-0.527 -2.292,-1.148c0,0 -0.502,1.909 -0.623,2.378c-0.226,0.868 -0.835,1.958 -1.243,2.622c0.935,0.287 1.929,0.444 2.96,0.444c5.522,0 10,-4.478 10,-10c0,-5.522 -4.477,-10 -10,-10z"></path>
        </g>
      </g>
    </svg>
  );
};

export const YoutubeHeaderIcon = ({ color = "#4d4d4d" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0,0,256,256"
    >
      <g
        fill={color}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: "normal" }}
      >
        <g transform="scale(10.66667,10.66667)">
          <path d="M21.582,6.186c-0.23,-0.86 -0.908,-1.538 -1.768,-1.768c-1.56,-0.418 -7.814,-0.418 -7.814,-0.418c0,0 -6.254,0 -7.814,0.418c-0.86,0.23 -1.538,0.908 -1.768,1.768c-0.418,1.56 -0.418,5.814 -0.418,5.814c0,0 0,4.254 0.418,5.814c0.23,0.86 0.908,1.538 1.768,1.768c1.56,0.418 7.814,0.418 7.814,0.418c0,0 6.254,0 7.814,-0.418c0.861,-0.23 1.538,-0.908 1.768,-1.768c0.418,-1.56 0.418,-5.814 0.418,-5.814c0,0 0,-4.254 -0.418,-5.814zM10,15.464v-6.928l6,3.464z"></path>
        </g>
      </g>
    </svg>
  );
};

export const TwitterHeaderIcon = ({ color = "#4d4d4d" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24"
      height="24"
      viewBox="0,0,256,256"
    >
      <g
        fill={color}
        fillRule="nonzero"
        stroke="none"
        strokeWidth="1"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeDasharray=""
        strokeDashoffset="0"
        fontFamily="none"
        fontWeight="none"
        fontSize="none"
        textAnchor="none"
        style={{ mixBlendMode: "normal" }}
      >
        <g transform="scale(10.66667,10.66667)">
          <path d="M22,3.999c-0.78,0.463 -2.345,1.094 -3.265,1.276c-0.027,0.007 -0.049,0.016 -0.075,0.023c-0.813,-0.802 -1.927,-1.299 -3.16,-1.299c-2.485,0 -4.5,2.015 -4.5,4.5c0,0.131 -0.011,0.372 0,0.5c-3.353,0 -5.905,-1.756 -7.735,-4c-0.199,0.5 -0.286,1.29 -0.286,2.032c0,1.401 1.095,2.777 2.8,3.63c-0.314,0.081 -0.66,0.139 -1.02,0.139c-0.581,0 -1.196,-0.153 -1.759,-0.617c0,0.017 0,0.033 0,0.051c0,1.958 2.078,3.291 3.926,3.662c-0.375,0.221 -1.131,0.243 -1.5,0.243c-0.26,0 -1.18,-0.119 -1.426,-0.165c0.514,1.605 2.368,2.507 4.135,2.539c-1.382,1.084 -2.341,1.486 -5.171,1.486h-0.964c1.788,1.146 4.065,2.001 6.347,2.001c7.43,0 11.653,-5.663 11.653,-11.001c0,-0.086 -0.002,-0.266 -0.005,-0.447c0,-0.018 0.005,-0.035 0.005,-0.053c0,-0.027 -0.008,-0.053 -0.008,-0.08c-0.003,-0.136 -0.006,-0.263 -0.009,-0.329c0.79,-0.57 1.475,-1.281 2.017,-2.091c-0.725,0.322 -1.503,0.538 -2.32,0.636c0.834,-0.5 2.019,-1.692 2.32,-2.636z"></path>
        </g>
      </g>
    </svg>
  );
};
